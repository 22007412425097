.SearchBox{
    padding-top: 20px;
    position:relative;
    margin:0 auto;
    clear:left;
    height:auto;
    z-index: 0;
    text-align:center;/* Add This*/
}


.ChartTitle{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.Hello{
    background-color: #282c34;
}